/* eslint-disable arrow-body-style */
<template>
  <b-overlay
    id="overlay-background"
    :show="dataLoading"
    variant="light"
    opacity="0.30"
    blur="10px"
    rounded="sm"
  >
    <div>
      <div>
        <div>
          <b-row>
            <b-col md="3">
              <div class="form-label-group">
                <b-form-input
                  id="floating-label1"
                  v-model="rateCardName"
                  placeholder="Rate Card Name"
                  class="mt-2"
                  autocomplete="nope"
                  disabled
                />
              </div>
            </b-col>
            <b-col
              md="9"
              class="d-flex justify-content-end"
            >
              <b-form-checkbox
                v-model="is_table_view"
                :checked="is_table_view"
                name="table-view-switch"
                switch
                inline
                class="mt-2"
              >
                <span
                  :class="is_table_view? 'font-weight-bold' : 'text-muted font-weight-bold' "
                >
                  Table View
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <br>
        </div>
      </div>
      <div v-if="is_table_view">
        <rate-card-table-view
          :linear_sections="linear_sections"
          :bucket_sections="bucket_sections"
          :city-list="CityList"
          :zone-list="ZoneList"
        />
      </div>
      <div v-if="!is_table_view">
        <div>
          <b-card
            v-for="(section, section_index) in linear_sections"
            :key="section_index"
          >
            <div>
              <b-row>
                <b-col md="3">
                  <span class="font-weight-bold">Section #{{ section_index+1 }} - {{ section.section_type }} Rate Card #{{ section_index+1 }}</span>
                </b-col>
                <b-col md="3" />
                <b-col md="3" />
                <b-col md="3" />
              </b-row>
              <b-row>
                <b-col md="3">
                  <span
                    style="visibility: hidden"
                  >Spacing for 1 row</span>
                </b-col>
                <b-col md="3" />
              </b-row>
            </div>
            <div>
              <ValidationObserver
                ref="createCityForm"
                slim
              >
                <b-form
                  class="my-8 border border-dimgray rounded"
                >
                  <b-row class="mt-2 mr-1 ml-1">
                    <b-col
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Delivery Method"
                        label-for="v-name"
                      >
                        <v-select
                          v-model="section.amount.delivery_method"
                          :options="liearpricingMethod"
                          label="name"
                          :reduce="option => option.value"
                          placeholder="Select Method"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.delivery_method === 'per_kg'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        v-b-tooltip.hover.top="'Delivery Charge Margin (kg)'"
                        label="Delivery Charge Margin"
                        label-for="v-del-ao"
                      >
                        <b-form-input
                          id="delivery_weight_margin"
                          v-model="section.amount.delivery_weight_margin"
                          type="number"
                          autocomplete="nope"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.delivery_method === 'per_kg'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Delivery Charge Margin Rate"
                        label-for="v-ret-ao"
                      >
                        <b-form-input
                          id="delivery_weight_margin_rate"
                          v-model="section.amount.delivery_weight_margin_rate"
                          type="number"
                          autocomplete="nope"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.delivery_method === 'per_kg'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Delivery Charge After Margin Rate"
                        label-for="v-ret-ao"
                      >
                        <b-form-input
                          id="delivery_weight_after_margin_rate"
                          v-model="section.amount.delivery_weight_after_margin_rate"
                          type="number"
                          autocomplete="nope"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.delivery_method === 'per_order'"
                      cols="2"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Delivery Charge"
                        label-for="v-ret-ao"
                      >
                        <b-form-input
                          id="delivery_rate"
                          v-model="section.amount.delivery_rate"
                          type="number"
                          autocomplete="nope"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1 mr-1 ml-1">
                    <b-col
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Return Method"
                        label-for="v-name"
                      >
                        <v-select
                          v-model="section.amount.return_method"
                          :options="liearpricingMethod"
                          label="name"
                          :reduce="option => option.value"
                          placeholder="Select Method"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.return_method === 'per_kg'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        v-b-tooltip.hover.top="'Return Charge Margin (kg)'"
                        label="Return Charge Margin"
                        label-for="v-del-ao"
                      >
                        <b-form-input
                          id="return_weight_margin"
                          v-model="section.amount.return_weight_margin"
                          type="number"
                          autocomplete="nope"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.return_method === 'per_kg'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Return Charge Margin Rate"
                        label-for="v-ret-ao"
                      >
                        <b-form-input
                          id="return_weight_margin_rate"
                          v-model="section.amount.return_weight_margin_rate"
                          type="number"
                          autocomplete="nope"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.return_method === 'per_kg'"
                      cols="6"
                      lg="3"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Return Charge After Margin Rate"
                        label-for="v-ret-ao"
                      >
                        <b-form-input
                          id="return_weight_after_margin_rate"
                          v-model="section.amount.return_weight_after_margin_rate"
                          type="number"
                          autocomplete="nope"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="section.amount.return_method === 'per_order'"
                      cols="2"
                      class="mobile-width-fix"
                    >
                      <b-form-group
                        label="Return Charge"
                        label-for="return_rate"
                      >
                        <b-form-input
                          id="return_rate"
                          v-model="section.amount.return_rate"
                          type="number"
                          autocomplete="nope"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <span
                        style="visibility: hidden"
                      >Spacing for 1 row</span>
                    </b-col>
                    <b-col md="3" />
                  </b-row>
                </b-form>
                <b-row>
                  <b-col md="3">
                    <span
                      style="visibility: hidden"
                    >Spacing for 1 row</span>
                  </b-col>
                  <b-col md="3" />
                </b-row>
                <b-row>
                  <b-col cols="1" />
                  <b-col cols="11">
                    <div>
                      <div>

                        <!-- Row Loop -->
                        <b-row
                          v-for="(city, city_index) in section.cities"
                          :key="city_index"
                        >

                          <b-col md="4">
                            <b-form-group
                              label="Origin"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.from_type"
                                :options="originMethods"
                                label="title"
                                autocomplete="nope"
                                placeholder="City/Zone"
                                disabled
                              />
                            </b-form-group>
                            <b-form-group
                              label="Origin City"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.from_city"
                                :options="(city.from_type === 'city' )? CityList : ZoneList"
                                :reduce="option => option.id"
                                label="name"
                                autocomplete="nope"
                                placeholder="Select City/Zone"
                                disabled
                              >
                                <template v-slot:option="option">
                                  {{ option.name }}
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="4">
                            <b-form-group
                              label="End"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.to_type"
                                :options="originMethods"
                                label="title"
                                autocomplete="nope"
                                placeholder="City/Zone"
                                disabled
                              />
                            </b-form-group>
                            <b-form-group
                              label="Destination City"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.to_cities"
                                :options="(city.to_type === 'city' )? CityList : ZoneList"
                                :reduce="option => option.id"
                                label="name"
                                autocomplete="nope"
                                placeholder="Select City/Zone"
                                multiple
                                deselect-from-dropdown
                                style="background-color: #f0f0f0;"
                              >
                                <template
                                  v-slot:option="option"
                                >
                                  {{ option.name }}
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group
                              label=""
                              label-for="item-name"
                            >
                              <b-form-checkbox
                                v-model="city.is_bidirectional"
                                v-b-tooltip.hover.v-danger
                                title="Check this if only direction is Bidirectional"
                                class="ml-5 mt-4"
                                value="true"
                                disabled
                              />
                              <span
                                class="ml-2"
                              >Bidirectional</span>
                            </b-form-group>
                          </b-col>
                          <b-col md="12">
                            <hr>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </ValidationObserver>
            </div>
          </b-card>
        </div>
      </div>
      <!-- End Linear Rate Card Section -->
      <!-- Start Bucket Rate Card Section -->
      <div v-if="!is_table_view">
        <div>
          <b-card
            v-for="(section, section_index) in bucket_sections"
            :key="section_index"
          >
            <div>
              <b-row>
                <b-col md="3">
                  <span class="font-weight-bold">Section #{{ section_index+1 }} - {{ section.section_type }} Rate Card #{{ section_index+1 }}</span>
                </b-col>
                <b-col md="3" />
                <b-col md="2" />
                <b-col md="3">
                  <v-select
                    v-model="section.amount.method"
                    :options="bucketPricingMethods"
                    :reduce="option => option.value"
                    label="name"
                    autocomplete="nope"
                    placeholder="Pricing Method"
                    disabled
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <span
                    style="visibility: hidden"
                  >Spacing for 1 row</span>
                </b-col>
                <b-col md="3" />
              </b-row>
            </div>
            <div>
              <ValidationObserver
                ref="createCityForm"
                slim
              >
                <b-form
                  class="my-8 border border-dimgray rounded"
                >
                  <b-row>
                    <b-col md="3">
                      <span
                        style="visibility: hidden"
                      >Spacing for 1 row</span>
                    </b-col>
                    <b-col md="3" />
                  </b-row>
                  <b-row
                    v-for="(weight, weight_index) in section.amount.weights"
                    :key="weight_index"
                  >
                    <b-col
                      cols="1"
                    />
                    <b-col cols="3">
                      <b-form-group
                        label="From"
                        label-for="v-del-ao"
                      >
                        <b-form-input
                          id="v-del-ao"
                          v-model="weight.from"
                          type="number"
                          autocomplete="nope"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="3">
                      <b-form-group
                        label="To"
                        label-for="v-del-ao"
                      >
                        <b-form-input
                          id="v-del-ao"
                          v-model="weight.to"
                          type="number"
                          autocomplete="nope"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <b-form-group
                        label="Delivery Amount"
                        label-for="v-ret-ao"
                      >
                        <b-form-input
                          id="v-ret-ao"
                          v-model="weight.delivery_amount"
                          type="number"
                          autocomplete="nope"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <b-form-group
                        label="Return Amount"
                        label-for="v-ret-ao"
                      >
                        <b-form-input
                          id="v-ret-ao"
                          v-model="weight.return_amount"
                          type="number"
                          autocomplete="nope"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <span
                        style="visibility: hidden"
                      >Spacing for 1 row</span>
                    </b-col>
                    <b-col md="3" />
                  </b-row>
                </b-form>
                <b-row>
                  <b-col md="3">
                    <span
                      style="visibility: hidden"
                    >Spacing for 1 row</span>
                  </b-col>
                  <b-col md="3" />
                </b-row>
                <b-row>
                  <b-col cols="1" />
                  <b-col cols="11">
                    <div>
                      <div>
                        <!-- Row Loop -->
                        <b-row
                          v-for="(city, city_index) in section.cities"
                          :key="city_index"
                        >

                          <b-col md="4">
                            <b-form-group
                              label="Origin"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.from_type"
                                :options="originMethods"
                                label="title"
                                autocomplete="nope"
                                placeholder="City/Zone"
                                disabled
                              />
                            </b-form-group>
                            <b-form-group
                              label="Origin City"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.from_city"
                                :options="(city.from_type === 'city' )? CityList : ZoneList"
                                :reduce="option => option.id"
                                label="name"
                                autocomplete="nope"
                                placeholder="'Select City/Zone"
                                disabled
                              >
                                <template v-slot:option="option">
                                  {{ option.name }}
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="4">
                            <b-form-group
                              label="End"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.to_type"
                                :options="originMethods"
                                label="title"
                                autocomplete="nope"
                                placeholder="City/Zone"
                                disabled
                              />
                            </b-form-group>
                            <b-form-group
                              label="Destination City"
                              label-for="item-name"
                            >
                              <v-select
                                v-model="city.to_cities"
                                :options="(city.to_type === 'city' )? CityList : ZoneList"
                                :reduce="option => option.id"
                                label="name"
                                autocomplete="nope"
                                placeholder="'Select City/Zone"
                                multiple
                                deselect-from-dropdown
                                style="background-color: #f0f0f0"
                              >
                                <template
                                  v-slot:option="option"
                                >
                                  {{ option.name }}
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>

                          <b-col md="2">
                            <b-form-group
                              label=""
                              label-for="item-name"
                            >
                              <b-form-checkbox
                                v-model="city.is_bidirectional"
                                v-b-tooltip.hover.v-danger
                                title="Check this if only direction is Bidirectional"
                                class="ml-5 mt-4"
                                value="true"
                                disabled
                              />
                              <span
                                class="ml-2"
                              >Bidirectional</span>
                            </b-form-group>
                          </b-col>
                          <b-col md="12">
                            <hr>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </ValidationObserver>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BForm,
  BRow,
  VBModal,
  BFormInput,
  BOverlay,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import RateCardTableView from './rateCardTableView.vue'

const ResourceRepository = RepositoryFactory.get('resource')
const ResourceBusinessSettingRepository = RepositoryFactory.get('businessSettings')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCol,
    BFormGroup,
    BForm,
    BOverlay,
    BRow,
    vSelect,
    BFormInput,
    BCard,
    BFormCheckbox,
    RateCardTableView,
  },
  mixins: [SuccessMessage, ErrorMessage, heightTransition],
  data() {
    return {
      loading: false,
      dataLoading: false,
      form: {},
      rateCardName: '',
      originMethods: ['city', 'zone'],
      liearpricingMethod: [
        { name: 'Per Kg', value: 'per_kg' },
        { name: 'Per Order', value: 'per_order' },
      ],
      bucketPricingMethods: [
        { name: 'Per Kg', value: 'per_kg' },
      ],
      CityList: [],
      ZoneList: [],
      // Start Section
      linear_sections: [
        {
          section_type: 'linear',
          amount: {
            delivery_method: 'per_kg',
            delivery_weight_margin: '0',
            delivery_weight_margin_rate: '0',
            delivery_weight_after_margin_rate: '0',
            return_method: 'per_kg',
            return_weight_margin: '0',
            return_weight_margin_rate: '0',
            return_weight_after_margin_rate: '0',
          },
          cities: [
            {
              from_type: '',
              from_city: '',
              to_type: '',
              to_city: '',
              to_cities: [],
              is_bidirectional: '',
            },
          ],
        },
      ],
      bucket_sections: [],
      // End Section
      direction: ['Bidirectional', 'Unidirectional'],
      id: null,
      is_table_view: false,
    }
  },
  watch: {
    is_table_view(val) {
      if (!val) {
        this.setDropdownStyles()
      }
    },
  },
  created() {
    this.id = this.$route.params.id
  },
  async mounted() {
    this.dataLoading = true
    await this.readDataforCity()
    await this.readDataforZone()
    await this.fetchRateCardDetails()
    this.setDropdownStyles()
    this.dataLoading = false
  },
  methods: {
    async readDataforCity() {
      try {
        this.CityList = localStorage.getItem('city_list') ? JSON.parse(localStorage.getItem('city_list')) : (await ResourceRepository.getCityListDropdownList()).data.data
        if (!localStorage.getItem('city_list')) localStorage.setItem('city_list', JSON.stringify(this.CityList))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforZone() {
      try {
        this.ZoneList = localStorage.getItem('zone_list') ? JSON.parse(localStorage.getItem('zone_list')) : (await ResourceRepository.getZoneListDropdown()).data.data
        if (!localStorage.getItem('zone_list')) localStorage.setItem('zone_list', JSON.stringify(this.ZoneList))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchRateCardDetails() {
      try {
        const { data } = await ResourceBusinessSettingRepository.getMerchantRateCard(this.id)
        this.rateCardName = data.data.card_name
        this.rows = data.data
        this.linear_sections = this.rows.form_data.filter(n => n.section_type === 'linear')
        this.linear_sections.forEach((n, indexn) => {
          n.cities.map(async (m, indexm) => {
            const mappedCities = []
            if (m.from_type === 'city') {
              this.linear_sections[indexn].cities[indexm].from_type = 'city'
            }
            if (m.from_type === 'zone') {
              this.linear_sections[indexn].cities[indexm].from_type = 'zone'
            }
            if (m.to_type === 'city') {
              this.linear_sections[indexn].cities[indexm].to_type = 'city'
            }
            if (m.to_type === 'zone') {
              this.linear_sections[indexn].cities[indexm].to_type = 'zone'
            }
            const alikeCities = await n.cities.filter(c => c.from_city === m.from_city && c.from_type === m.from_type && c.to_type === m.to_type && c.is_bidirectional === m.is_bidirectional)
            alikeCities.forEach((c, index) => {
              mappedCities.push(c.to_city)
              // eslint-disable-next-line no-param-reassign
              if (index !== 0) n.cities = n.cities.filter(item => item !== c)
            })
            // eslint-disable-next-line no-param-reassign
            m.to_cities = mappedCities
          })
        })
        this.bucket_sections = this.rows.form_data.filter(n => n.section_type === 'bucket')
        this.bucket_sections.forEach((n, indexn) => {
          n.cities.map(async (m, indexm) => {
            const mappedCities = []
            if (m.from_type === 'city') {
              this.bucket_sections[indexn].cities[indexm].from_type = 'city'
            }
            if (m.from_type === 'zone') {
              this.bucket_sections[indexn].cities[indexm].from_type = 'zone'
            }
            if (m.to_type === 'city') {
              this.bucket_sections[indexn].cities[indexm].to_type = 'city'
            }
            if (m.to_type === 'zone') {
              this.bucket_sections[indexn].cities[indexm].to_type = 'zone'
            }
            const alikeCities = await n.cities.filter(c => c.from_city === m.from_city && c.from_type === m.from_type && c.to_type === m.to_type && c.is_bidirectional === m.is_bidirectional)
            alikeCities.forEach((c, index) => {
              mappedCities.push(c.to_city)
              // eslint-disable-next-line no-param-reassign
              if (index !== 0) n.cities = n.cities.filter(item => item !== c)
            })
            // eslint-disable-next-line no-param-reassign
            m.to_cities = mappedCities
          })
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    onSubmit() {
      if (this.is_update) {
        this.rateCardName = this.rateCardName.replaceAll('Rate Card - ', '')
      }
      this.linear_sections.forEach((n, index) => {
        const { amount } = n
        // eslint-disable-next-line camelcase
        const new_amount = {}
        if (amount.delivery_method === 'per_kg') {
          new_amount.delivery_method = 'per_kg'
          new_amount.delivery_weight_margin = amount.delivery_weight_margin
          new_amount.delivery_weight_margin_rate = amount.delivery_weight_margin_rate
          new_amount.delivery_weight_after_margin_rate = amount.delivery_weight_after_margin_rate
        }
        if (amount.return_method === 'per_kg') {
          new_amount.return_method = 'per_kg'
          new_amount.return_weight_margin = amount.return_weight_margin
          new_amount.return_weight_margin_rate = amount.return_weight_margin_rate
          new_amount.return_weight_after_margin_rate = amount.return_weight_after_margin_rate
        }
        if (amount.delivery_method === 'per_order') {
          new_amount.delivery_method = 'per_order'
          new_amount.delivery_rate = amount.delivery_rate
        }
        if (amount.return_method === 'per_order') {
          new_amount.return_method = 'per_order'
          new_amount.return_rate = amount.return_rate
        }
        // eslint-disable-next-line camelcase
        this.linear_sections[index].amount = new_amount
      })

      if (this.rateCardName === '' || this.rateCardName === null) {
        this.showErrorMessage('Please Enter an Rate Card Name')
      } else if (this.bucket_sections.length === 0) {
        // eslint-disable-next-line arrow-body-style
        const linearSections = this.linear_sections.map(n => {
          const mappedCities = []
          // eslint-disable-next-line arrow-body-style
          n.cities.forEach(m => {
            m.to_cities.forEach(o => {
              mappedCities.push({
                from_type: m.from_type,
                from_city: m.from_city,
                to_type: m.to_type,
                to_city: o,
                is_bidirectional: m.is_bidirectional,
              })
            })
          })
          return {
            section_type: n.section_type,
            amount: n.amount,
            cities: mappedCities,
          }
        })
        const all = linearSections

        const rateCard = {
          rate_card_general_details: {
            card_name: `Rate Card - ${this.rateCardName}`,
          },
          sections: all,
        }
        this.handleSubmit(rateCard)
      } else {
        // eslint-disable-next-line arrow-body-style
        const linearSections = this.linear_sections.map(n => {
          const mappedCities = []
          // eslint-disable-next-line arrow-body-style
          n.cities.forEach(m => {
            m.to_cities.forEach(o => {
              mappedCities.push({
                from_type: m.from_type,
                from_city: m.from_city,
                to_type: m.to_type,
                to_city: o,
                is_bidirectional: m.is_bidirectional,
              })
            })
          })
          return {
            section_type: n.section_type,
            amount: n.amount,
            cities: mappedCities,
          }
        })
        const bucketSections = this.bucket_sections.map(n => {
          const mappedCities = []
          n.cities.forEach(m => {
            m.to_cities.forEach(o => {
              mappedCities.push({
                from_type: m.from_type,
                from_city: m.from_city,
                to_type: m.to_type,
                to_city: o,
                is_bidirectional: m.is_bidirectional,
              })
            })
          })
          return {
            section_type: n.section_type,
            amount: n.amount,
            cities: mappedCities,
          }
        })
        const all = linearSections.concat(bucketSections)

        const rateCard = {
          rate_card_general_details: {
            card_name: `Rate Card - ${this.rateCardName}`,
          },
          sections: all,
        }
        this.handleSubmit(rateCard)
      }
    },
    setDropdownStyles() {
      this.$nextTick(() => {
        document.querySelectorAll('.vs__selected-options , .vs__actions').forEach(n => {
          // eslint-disable-next-line no-unused-vars
          n.addEventListener('click', e => {
            const dropdownElements = document.querySelectorAll('.vs__dropdown-option')
            dropdownElements.forEach(item => {
              // eslint-disable-next-line no-param-reassign
              item.style.pointerEvents = 'none'
            })
          })
        })
      })
    },
  },
}
</script>
<style scoped lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';

:root {
--bucketcolor: #6f57e8
}

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
@media (max-width: 767px) {
  .mobile-width-fix {
    max-width: 100%;
    flex-basis: 100%;
  }
}
</style>
