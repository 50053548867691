<template>
  <div>
    <b-table
      striped
      bordered
      hover
      responsive
      :items="linear_sections"
      :fields="fields"
    >
      <template #cell(origin)="data">
        <origin-city-block
          :id="data.item.city.from_city"
          :type="data.item.city.from_type"
          :is-bidirectional="data.item.city.is_bidirectional"
          :city-list="cityList"
          :zone-list="zoneList"
        />
      </template>
      <template #cell(destination)="data">
        <destination-city-block
          :city-ids="data.item.city.to_cities"
          :type="data.item.city.to_type"
          :city-list="cityList"
          :zone-list="zoneList"
        />
      </template>
      <template #cell(amount.delivery_method)="data">
        <div
          class="font-weight-bold"
          style="text-transform: capitalize;"
        >
          {{ data.value.replaceAll('_', ' ') }}
        </div>
      </template>
      <template #cell(amount.delivery_weight_margin)="data">
        {{ data.value || '---' }}
      </template>
      <template #cell(amount.delivery_weight_margin_rate)="data">
        <div class="font-weight-bold">
          {{ data.item.amount.delivery_method === 'per_kg' ? data.value : data.item.amount.delivery_rate }}
        </div>
      </template>
      <template #cell(amount.delivery_weight_after_margin_rate)="data">
        {{ data.value || '---' }}
      </template>
      <template #cell(amount.return_method)="data">
        <div
          class="font-weight-bold"
          style="text-transform: capitalize;"
        >
          {{ data.value.replaceAll('_', ' ') }}
        </div>
      </template>
      <template #cell(amount.return_weight_margin)="data">
        {{ data.value || '---' }}
      </template>
      <template #cell(amount.return_weight_margin_rate)="data">
        <div class="font-weight-bold">
          {{ data.item.amount.return_method === 'per_kg' ? data.value : data.item.amount.return_rate }}
        </div>
      </template>
      <template #cell(amount.return_weight_after_margin_rate)="data">
        {{ data.value || '---' }}
      </template>
    </b-table>
  </div>
</template>

<script>

/* eslint-disable vue/prop-name-casing */
import {
  BTable,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import OriginCityBlock from '@core/components/rate-card/table-components/OriginCityBlock.vue'
import DestinationCityBlock from '@core/components/rate-card/table-components/DestinationCityBlock.vue'

export default {
  components: {
    BTable,
    OriginCityBlock,
    DestinationCityBlock,
  },
  mixins: [SuccessMessage, ErrorMessage, heightTransition],
  props: {
    linear_sections: {
      type: Array,
      default() {
        return []
      },
    },
    cityList: {
      type: Array,
      default() {
        return []
      },
    },
    zoneList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      fields: [
        { key: 'origin', label: 'Origin', sortable: true },
        { key: 'destination', label: 'Destination', sortable: false },
        { key: 'amount.delivery_method', label: 'Method', sortable: false },
        { key: 'amount.delivery_weight_margin', label: 'Margin', sortable: false },
        { key: 'amount.delivery_weight_margin_rate', label: 'Rate', sortable: false },
        { key: 'amount.delivery_weight_after_margin_rate', label: 'Extra', sortable: false },
        { key: 'amount.return_method', label: 'Return Method', sortable: false },
        { key: 'amount.return_weight_margin', label: 'Return Margin', sortable: false },
        { key: 'amount.return_weight_margin_rate', label: 'Return Rate', sortable: false },
        { key: 'amount.return_weight_after_margin_rate', label: 'Return Extra', sortable: false },
      ],
    }
  },
}
</script>
