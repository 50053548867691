<template>
  <div>
    <hr>
    <b-badge
      :variant="type === 'city' ? 'light-success' : 'light-warning'"
    >
      {{ type.charAt(0).toUpperCase() + type.substring(1) }}
    </b-badge>
    <hr>
    <div style="margin-bottom:5px;">
      {{ type === 'city' ? cityList.filter(city => city.id === id)[0].name : zoneList.filter(zone => zone.id === id)[0].name }}
    </div>
    <div
      v-if="isBidirectional"
    >
      <hr>
      <b-badge
        variant="light-primary"
        style="margin-bottom:5px;"
      >
        Bidirectional
      </b-badge>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/prop-name-casing */
import {
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      default: 0,
    },
    isBidirectional: {
      type: Boolean,
      default: false,
    },
    cityList: {
      type: Array,
      default() {
        return []
      },
    },
    zoneList: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>
