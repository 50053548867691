<template>
  <div>
    <hr>
    <b-badge
      :variant="type === 'city' ? 'light-success' : 'light-warning'"
    >
      {{ type === 'city' ? 'Cities' : 'Zones' }}
    </b-badge>
    <div
      v-for="(city_id, index) in cityIds"
      :key="index"
    >
      <hr>
      <div style="margin-bottom:5px; margin-top:5px">
        {{ type === 'city' ? cityList.filter(city => city.id === city_id)[0].name : zoneList.filter(zone => zone.id === city_id)[0].name }}
      </div>

    </div>
  </div>
</template>

<script>
/* eslint-disable vue/prop-name-casing */
import {
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    cityIds: {
      type: Array,
      default() {
        return []
      },
    },
    cityList: {
      type: Array,
      default() {
        return []
      },
    },
    zoneList: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>
